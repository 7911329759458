// extracted by mini-css-extract-plugin
export var blogsCard = "blogs-module--blogs-card--pYRm7";
export var blogsCardDate = "blogs-module--blogs-card-date--o2MPr";
export var blogsCardImage = "blogs-module--blogs-card-image--cvzxb";
export var blogsCardImageWrapper = "blogs-module--blogs-card-image-wrapper--2pBBS";
export var blogsCardLink = "blogs-module--blogs-card-link--J3-tA";
export var blogsCardTag = "blogs-module--blogs-card-tag--Fw2xB";
export var blogsCardText = "blogs-module--blogs-card-text--n4ilj";
export var blogsCardTitle = "blogs-module--blogs-card-title--DIMJ6";
export var blogsCardWrapper = "blogs-module--blogs-card-wrapper--u-kok";
export var blogsContainer = "blogs-module--blogs-container--iK+mY";
export var blogsHr = "blogs-module--blogs-hr--Of0sr";
export var blogsPagination = "blogs-module--blogs-pagination--VOLTG";
export var blogsPaginationCurrentPage = "blogs-module--blogs-pagination-current-page--JeidY";
export var blogsPaginationLink = "blogs-module--blogs-pagination-link--pAYap";
export var blogsText = "blogs-module--blogs-text--vwFZC";
export var blogsTitle = "blogs-module--blogs-title--NEHkR";