import * as React from 'react'
import { Link, graphql } from 'gatsby'
import Layout from '../components/layout'
import {
  blogsContainer,
  blogsTitle,
  blogsText,
  blogsHr,
  blogsCardWrapper,
  blogsCard,
  blogsCardLink,
  blogsCardImageWrapper,
  blogsCardImage,
  blogsCardTag,
  blogsCardDate,
  blogsCardTitle,
  blogsCardText,
  blogsPagination,
  blogsPaginationLink,
  blogsPaginationCurrentPage,
} from '../styles/blogs.module.css'

const Blogs = (props) => {
  const blogPosts = props.data.allContentfulBlogPost.edges
  const { currentPage, numPostsPages } = props.pageContext
  const isFirst = currentPage === 1
  const isLast = currentPage === numPostsPages
  const prevPage = currentPage - 1 === 1 ? "/blogs" : `/blogs/${(currentPage - 1).toString()}`
  const nextPage = `/blogs/${(currentPage + 1).toString()}`

  return (
    <Layout
      isHome={false}
      title="NEUTRAL Blog「好き」と「仕事」の交差点"
      description="「好きを仕事に」を応援し、成長させる！NEUTRALのブログです。NEUTRALの最新情報や技術関連、プライベート、「好きを仕事に」している人へのインタビュー記事などをお届けします。"
    >
      <div className={blogsContainer}>
        <h1 className={blogsTitle}>NEUTRAL Blog</h1>
        <p className={blogsText}>
          NEUTRALの最新情報や技術関連、<br/>
          プライベート、「好きを仕事に」している人への<br/>
          インタビュー記事などをお届けします
        </p>
        <hr className={blogsHr}/>
        <div className={blogsCardWrapper}>
          {blogPosts.map(({ node: post }) => (
            <div key={post.id} className={blogsCard}>
              <Link to={`/blog/${post.slug}`} className={blogsCardLink}>
                <div className={blogsCardImageWrapper}>
                  <img
                    className={blogsCardImage}
                    alt={post.title}
                    src={post.thumbnail.file.url}
                  />
                  {post.tag && <div className={blogsCardTag}>{`#${post.tag.title}`}</div>}
                </div>
              </Link>
              <p className={blogsCardDate}>{post.publishedDate}</p>
              <h2 className={blogsCardTitle}>{post.title}</h2>
              <p className={blogsCardText}>{post.description.description}</p>
            </div>
          ))}
        </div>
        <div className={blogsPagination}>
          {!isFirst && <Link className={blogsPaginationLink} to={prevPage} rel="prev">← prev</Link>}
          {Array.from({ length: numPostsPages }, (_, i) => (
            <Link className={blogsPaginationLink} key={`pagination-number${i + 1}`} to={`/blogs/${i === 0 ? "" : i + 1}`}>
              <p className={i + 1 === currentPage ? blogsPaginationCurrentPage : null}>{i + 1}</p>
            </Link>
          ))}
          {!isLast && <Link className={blogsPaginationLink} to={nextPage} rel="next">next →</Link>}
        </div>
      </div>
    </Layout>
  )
}

export default Blogs

export const blogsQuery = graphql`
  query BlogsQuery($skip: Int!, $limit: Int!) {
    allContentfulBlogPost(
      sort: { fields: publishedDate, order: DESC }
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          id
          title
          slug
          publishedDate(formatString: "YYYY.MM.DD")
          description {
            description
          }
          thumbnail {
            file {
              url
            }
          }
          tag {
            title
            slug
          }
        }
      }
    }
  }
`